<template>
  <div>
    <HgShortTripSearch
      allowHotjarRecord
      :is-mobile="isMobile"
      :destinations="orderedDestinations"
      :search-url="searchUrl"
      @onUpdateDestinationTerm="updateDestinationTerm"
      @onUpdateSearchParameters="updateSearchParameters"
      @onTriggerSearch="triggerSearch"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { addDays } from 'date-fns';
import GtmEventsMixin from '@/mixins/GtmEventsMixin';
import travelcircusAutosuggestQuery from '@/apollo/queries/ibe/travelcircusAutosuggest.gql';
import travelcircusShortTripsSearchUrlQuery from '@/apollo/queries/ibe/travelcircusShortTripsSearchUrl.gql';

export default {
  mixins: [GtmEventsMixin],
  data: () => ({
    selectedDaterange: null,
    selectedTravelers: { adults: 2 },
    destinationTerm: '',
    orderedDestinations: [],
    searchUrl: ''
  }),

  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    })
  },

  methods: {
    async updateSearchParameters(event) {
      const { destination, dateRange, travelers } = event;
      if (destination == null || dateRange == null || travelers == null) {
        return;
      }

      this.selectedDaterange = event.dateRange;
      this.selectedTravelers = event.travelers || this.selectedTravelers;

      delete destination.__typename;

      try {
        const {
          data: { travelcircusShortTripsSearchUrl }
        } = await this.$apollo.query({
          query: travelcircusShortTripsSearchUrlQuery,
          variables: {
            input: {
              agesOfChildren: travelers.ages,
              checkinDate: addDays(dateRange.start, 1),
              checkoutDate: addDays(dateRange.end, 1),
              numberOfAdults: travelers.adults,
              destination
            }
          }
        });

        this.searchUrl = travelcircusShortTripsSearchUrl;
      } catch (e) {
        console.error(e.graphQLErrors);
      }
    },

    triggerSearch() {
      this.$gtm.push({
        event: 'TC_search_used',
        period: `${this.$dateFns.format(
          this.selectedDaterange.start,
          'EE, dd.MM.yyyy'
        )} - ${this.$dateFns.format(
          this.selectedDaterange.end,
          'EE, dd.MM.yyyy'
        )}`,
        destination: this.destinationTerm,
        travellers: {
          adults: this.selectedTravelers.adults,
          children: this.selectedTravelers.ages.length,
          ages: this.selectedTravelers.ages
        }
      });
    },

    updateDestinationTerm(term) {
      this.destinationTerm = term;
      this.queryDestinations();
    },

    async queryDestinations() {
      if (this.destinationTerm === '') return;

      try {
        const {
          data: { travelcircusAutosuggest }
        } = await this.$apollo.query({
          query: travelcircusAutosuggestQuery,
          variables: {
            search: this.destinationTerm
          }
        });

        if (travelcircusAutosuggest && travelcircusAutosuggest.length > 0) {
          const orderTypes = [];

          travelcircusAutosuggest.forEach((elem) => {
            if (!orderTypes.includes(elem.type)) {
              orderTypes.push(elem.type);
            }
          });

          // Order results by types
          const ordered = [];

          orderTypes.forEach((element) => {
            ordered.push(
              travelcircusAutosuggest.filter((elem) => {
                return elem.type === element;
              })
            );
          });

          this.orderedDestinations = [].concat(...ordered);
        }
      } catch (e) {
        console.error(e.graphQLErrors);
      }
    }
  }
};
</script>
